import React from 'react'
import {Link} from 'gatsby'
import { EuiCallOut, EuiText, EuiTitle, EuiSpacer } from '@elastic/eui'

import FloatingGallery from "../../../../../../components/Software/Windows/InstarVision/Windows_Phone/instarvision-wp-p2p-floating-gallery"

import P2PTable from '../../../../../../components/Software/Windows/InstarVision/Windows_Phone/instarvision-wp-p2p-table'

import NavButtons from '../../../../../../components/Software/Windows/InstarVision/Windows_Phone/NavButtons'

import SEOHelmet from '../../../../../../components/Layout/SEOHelmet'
import BreadCrumbs from '../../../../../../components/Layout/BreadCrumbs'


const seodata = {
  title: 'P2P Menu :: InstarVision for Windows Phone',
  description: 'The P2P Access does not give you full access to all your cameras functions. But you don´t need to forward a port inside your router and P2P also works.',
  image: '/images/Search/P_SearchThumb_InstarVision_WP.png',
  twitter: '/images/Search/P_SearchThumb_InstarVision_WP.webp',
  locationEN: '/en/Software/Windows/InstarVision/Windows_Phone/P2P/',
  locationDE: '/de/Software/Windows/InstarVision/Windows_Phone/P2P/',
  locationFR: '/fr/Software/Windows/InstarVision/Windows_Phone/P2P/'
}

function InstarVisionP2P(props) {
  return (
    <React.Fragment>

      <SEOHelmet
        title={seodata.title}
        description={seodata.description}
        image={seodata.image}
        twitter={seodata.twitter}
        location={props.location} />

      <BreadCrumbs
          locationBC={props.location}
          locationEN={seodata.locationEN}
          locationDE={seodata.locationDE}
          locationFR={seodata.locationFR}
          crumbLabel="P2P" />
          
      <NavButtons />

      <EuiTitle size="l">
        <h1>InstarVision Surveillance Center</h1>
      </EuiTitle>
      <EuiTitle size="m">
        <h2>Windows Phone Software</h2>
      </EuiTitle>
      <EuiTitle size="s">
        <h4>Add a Camera: <em>P2P Access</em></h4>
      </EuiTitle>
      <EuiSpacer size="l" />
      <EuiText>
        <p>Our HD camera line allows you to add the camera by a so-called p2p (<strong>Point-to-Point</strong>) connection. To establish the p2p connection, choose to add a New p2p Camera, type in the camera&apos;s UID, which can be found on the sticker on the camera and the web user interface (<Link to="/Web_User_Interface/720p_Series/Network/P2P/">720p</Link> / <Link to="/Web_User_Interface/1080p_Series/Network/Internet_Access/">1080p</Link>). Or use the QR code scanner to scan your camera&apos;s code. Please be aware that the QR code on the sticker contains your camera&apos;s default login. The QR code in the webUI is generated dynamically and will contain your UID as well as your personal camera login. If you change your camera´s administrator login or just activated the p2p service, you first have to restart your camera, before you can access it via your UID.</p>
      </EuiText >
      <EuiSpacer size="l" />
      <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Please be Aware:">
        <EuiText>
          <p><strong>P2P does not allow access to image files on the internal SD card. You can only access stored videos.</strong></p>
          <p>With INSTAR 720p camera (<strong>1080p cameras use an improved P2P version that is not affected!</strong>), you will not be able to change the video settings (bitrate, contrast, brightness, etc.).</p>
        </EuiText >
      </EuiCallOut>
      <EuiSpacer size="xl" />
      <FloatingGallery />
      <EuiSpacer size="xl" />
      <h3>Add an INSTAR P2P IP Camera</h3>
      <EuiSpacer size="l" />
      <P2PTable />
    </React.Fragment>
  );
}

export default InstarVisionP2P