import React, { useState } from 'react'

import {EuiButton} from "@elastic/eui"

import { AutoRotatingCarousel, Slide } from "material-auto-rotating-carousel"

import WinPhone01 from '../../../../../images/Software/Windows/InstarVision/Windows_Phone/WP_App1.webp'
import WinPhone02 from '../../../../../images/Software/Windows/InstarVision/Windows_Phone/Wizard2.webp'
import WinPhone03 from '../../../../../images/Software/Windows/InstarVision/Windows_Phone/Wizard12.webp'
import WinPhone04 from '../../../../../images/Software/Windows/InstarVision/Windows_Phone/Wizard13.webp'

const AutoRotatingCarouselModal = ({ handleOpen, setHandleOpen }) => {
  return (
    <div>
      <AutoRotatingCarousel
        label="Return"
        open={handleOpen.open}
        onClose={() => setHandleOpen({ open: false })}
        onStart={() => setHandleOpen({ open: false })}
        mobile
        autoplay={true}
        style={{ position: "absolute" }}
      >
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={WinPhone01} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera via its P2P address"
          subtitle="Go to the Multi View or Cameras menu and click the + button in the top right corner to add your camera."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={WinPhone02} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera via its P2P address"
          subtitle="Select New P2P Camera to add a new IP camera."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={WinPhone03} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera via its P2P address"
          subtitle="Either scan the QR Code from the web interface of your camera or press Search to find you on the local network."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={WinPhone04} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera via its P2P address"
          subtitle="Check the correctness of the UID, your admin login and press the Test button. Then press the diskette icon below."
        />
      </AutoRotatingCarousel>
    </div>
  );
}

function P2PGallery() {
  const [handleOpen, setHandleOpen] = useState({ open: false });
  const handleClick = () => {
    setHandleOpen({ open: true });
  };
  return (
    <>
      <EuiButton fill onClick={handleClick}>
        Image Gallery
      </EuiButton>
      <AutoRotatingCarouselModal
        handleOpen={handleOpen}
        setHandleOpen={setHandleOpen}
      />
    </>
  );
}


export default P2PGallery